import React from 'react'
import Layout from '@/components/Layout'
import NotFound from '@/components/pages/404NotFound'

// export { default } from '@/components/pages/404NotFound'

export default () => {
  return (
    <Layout title="Nilly's Burgers - Page Not Found">
      <NotFound />
    </Layout>
  )
}
