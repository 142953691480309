import React, { memo } from 'react'
import { Link } from 'gatsby'
import { Flex, Box, Text, useColorModeValue } from '@chakra-ui/react'
import Lottie from 'react-lottie-player'
import BurgerDoodleBlack from '@/assets/lottie/burger_doodle_black.json'
import BurgerDoodleWhite from '@/assets/lottie/burger_doodle_white.json'

/**
 * We couldn't find the page you were looking for. This is either because:

There is an error in the URL entered into your web browser. Please check the URL and try again.
The page you are looking for has been moved or deleted.
You can return to our homepage by clicking here, or you can try searching for the content you are seeking by clicking here.
 * @returns 
 */
const NotFound: React.FC = () => (
  <Flex id="error404Page" flexDirection="column" alignItems="center" p={2}>
    <Text
      as="h1"
      textStyle="h1"
      display="grid"
      alignItems="center"
      gridAutoFlow="column"
      gridTemplateColumns="1fr 60% 1fr"
    >
      <Box
        position="relative"
        left="50%"
        justifySelf="flex-end"
        fontSize={[100, 140, 200, 260]}
      >
        4
      </Box>
      <Box mb={6}>
        <Lottie
          loop
          animationData={useColorModeValue(
            BurgerDoodleBlack,
            BurgerDoodleWhite
          )}
          play
        />
      </Box>
      <Box position="relative" right="50%" fontSize={[100, 140, 200, 260]}>
        4
      </Box>
    </Text>
    <Text
      textAlign="center"
      textStyle="body"
      fontSize={['lg', 'xl', '2xl', '3xl']}
    >
      Whoops! Page not found
    </Text>
    <Link to="/">
      <Text
        textStyle="body"
        fontWeight="semibold"
        color="primary.main"
        transition="all 0.1s ease-in-out"
        textDecoration="underline"
        textDecorationColor="transparent"
        textUnderlineOffset="3px"
        _hover={{ color: 'primary.main', textDecorationColor: 'primary.main' }}
        _active={{ color: 'primary.main' }}
        _focus={{ color: 'primary.main' }}
        mt={2}
      >
        Go back to home
      </Text>
    </Link>
  </Flex>
)

export default memo(NotFound)
